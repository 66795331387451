import styled from "styled-components";
import { style } from "../../components/AppGlobalStyle";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Container = styled.div`
  background-color: ${style.backgroundClearComponent};
  border-radius: ${style.borderRadius};
  padding: 20px;
  box-shadow: ${style.boxshadow};
  z-index: 1001;
`;

export const Content = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 10px;
    color: ${style.backgroundClearComponentContrastText};
  }
`;

