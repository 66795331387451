import styled from "styled-components";
import { style } from "../../../../components/AppGlobalStyle";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  background: #11191C;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 100;

  img {
    width: 150px;
    height: 44px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 39.2px;
    height: 39.2px;
    border-radius: 50%;
    margin-right: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    span {
      color: ${style.secondarycontrastcolor};
    }

    a {
      color: ${style.primary};

      &:hover {
        opacity: 0.8;
        color: ${style.primary};
      }
    }
  }
`;

export const HeaderButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.2s;
  cursor: pointer;
  color: ${style.primary};

  svg {
    height: 20px;
    width: 20px;
  }

  span {
    margin-top: 10px;
  }

  &:hover {
    color: ${style.primary};
    svg {
      color: ${style.primary};
    }
    span {
      color: ${style.primary};
    }
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`;
