import styled from "styled-components";
import { style } from "../AppGlobalStyle";

export const MemberContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    background: ${style.backgroundClearComponent};
    border-radius: ${style.borderRadius};
    margin-bottom: 15px;
    box-shadow: ${style.boxshadow};
`;

export const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  background: #779;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 18px;
    color: ${style.backgroundClearComponentContrastText};
  }

  span {
    margin-top: 4px;
    font-size: 14px;
    color: ${style.backgroundClearComponentContrastText};
  }
`;