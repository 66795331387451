import React from "react";

import { Container, Content } from "./styles";
import Button from "../DefaultButton";

interface PanelProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

const Panel: React.FC<PanelProps> = ({ isOpen, onClose, className, children }) => {
  return (
    <Container className={className} isOpen={isOpen}>
      <Content>
        {children}
        <Button onClick={onClose}>Fechar</Button>
      </Content>
    </Container>
  );
};

export default Panel;