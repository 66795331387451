import React, { FC } from 'react';

interface StatsProps {
  // Adicione qualquer propriedade que você precise aqui
}

const Stats: FC<StatsProps> = () => {
  return (
    <div>
      <h2>Estatísticas</h2>
      {/* Aqui vão as estatísticas de jogadores, times, jogos, etc. */}
    </div>
  );
}

export default Stats;
