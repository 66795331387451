import React, { useState } from "react";
import "react-day-picker/lib/style.css";

import Panel from "../../components/Panel";
import Modal from "../../components/Modal";
import SideBar from "../../components/Sidebar";
import Button from "../../components/DefaultButton";
import ListMatches from "../../components/ListMatches";

import {
  Container,
  Content,
  Schedule,
  Section,
} from "./styles";

const DashboardRoutines: React.FC = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenPanel = () => setIsPanelOpen(true);
  const handleClosePanel = () => setIsPanelOpen(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // Código para criar rotinas simuladas
  const titulosAleatorios = [
    "Santos x Corinthians",
    "Corinthians x Palmeiras",
    "Corinthians x Santos",
    "Palmeiras x Corinthians",
    "Santos x Cruzeiro",
    "Cruzeiro x Palmeiras"

  ];

  const setoresAleatorios = ["Vila Belmiro", "Maracanã", "Itaquerão", "Allianz Park"];
  
  const papeisAleatorios = [
    "25/08 - 18h00",
    "03/08 - 15h00",
    "08/08 - 16h00",
    "16/09 - 21h00",
  ];

  const statusAleatorios = ["Conforme", "Não Conforme", "Não Executado"];

  const rotinas = Array.from({ length: 30 }, (_, index) => {
    const randomTitulo = titulosAleatorios[Math.floor(Math.random() * titulosAleatorios.length)];
    const randomSetor = setoresAleatorios[Math.floor(Math.random() * setoresAleatorios.length)];
    const randomPapel = papeisAleatorios[Math.floor(Math.random() * papeisAleatorios.length)];
    const randomStatus = statusAleatorios[Math.floor(Math.random() * statusAleatorios.length)];

    return {
      id: index + 4,
      avatar: `link-para-avatar-${index + 4}`,
      titulo: randomTitulo,
      setor: randomSetor,
      papel: randomPapel,
      status: randomStatus,
      opcao: "avaliar"
    };
  });

  return (
    <Container>
      <SideBar />

      <Content>
        <Schedule>
          <h1>Rotinas</h1>
        
          <Section>
            <div className="App">
            <Button onClick={handleOpenPanel}>Visualizar Rotina</Button>
            <Panel isOpen={isPanelOpen} onClose={handleClosePanel}>
            <h2>Painel</h2>
              <p>Este é o painel que você pode utilizar para visualizar um POP.</p>

              <p>Nome do POP</p>

              <p>Descrição do POP.</p>

              <p>Setor.</p>

              <p>Papel.</p>
            </Panel>
          </div>

          <div>
            <Button onClick={openModal}>Criar Rotina</Button>
            <Modal tituloDaModal="Criar POP" isOpen={modalOpen} onClose={closeModal}>
              <p>Este é o modal que você pode utilizar para Criar e editar um POP.</p>

              <p>Nome do POP</p>

              <p>Descrição do POP.</p>

              <p>Setor.</p>

              <p>Papel.</p>
            </Modal>
          </div>
          </Section>
          <Section>
            {/* Renderizando a lista de rotinas */}
            <div className="list">
              {rotinas.map((rotina) => (
                <ListMatches
                  key={rotina.id}
                  titulo={rotina.titulo}
                  setor={rotina.setor}
                  papel={rotina.papel}
                  status={rotina.status}
                  opcao={rotina.opcao}
                />
              ))}
            </div>

            {/* ... (Botões e Painéis) */}
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default DashboardRoutines; // Alterei o nome do componente
