import React, { useState, useEffect, FC } from 'react';
import DefaultButton from "../../../../components/DefaultButton";
import * as styles from './styles';

interface PlayerInputProps {
  onNext: () => void;
}

const PlayerInput: FC<PlayerInputProps> = ({ onNext }) => {
  const localStorageKey = "footbras:players";
  
  const storedPlayers = localStorage.getItem(localStorageKey);
  const initialPlayers = storedPlayers ? JSON.parse(storedPlayers) : [];
  
  const [players, setPlayers] = useState<string[]>(initialPlayers);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(players));
  }, [players]);

  const addMoreFields = () => {
    setPlayers([...players, '', '', '', '', '']);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newPlayers = [...players];
    newPlayers[index] = e.target.value;
    setPlayers(newPlayers);
  };

  const removeField = (index: number) => {
    const newPlayers = [...players];
    newPlayers.splice(index, 1);
    setPlayers(newPlayers);
  };

  const handleEdit = (index: number) => {
    setIsEditing(index);
  };

  const handleBlur = () => {
    setIsEditing(null);
  };

  return (
    <styles.PlayerInputContainer>
      <h2>Inserir Jogadores</h2>
      {players.map((player, index) => (
        <styles.PlayerRow key={index}>
          {isEditing === index ? (
            <input
              value={player}
              onChange={(e) => handleChange(e, index)}
              onBlur={handleBlur}
            />
          ) : (
            <styles.PlayerName onClick={() => handleEdit(index)}>{player || 'Clique para adicionar nome'}</styles.PlayerName>
          )}
          <DefaultButton onClick={() => removeField(index)}>X</DefaultButton>
        </styles.PlayerRow>
      ))}
      <DefaultButton onClick={addMoreFields}>Adicionar mais 5 campos</DefaultButton><br/>
      <DefaultButton onClick={onNext}>Confirmar</DefaultButton>
    </styles.PlayerInputContainer>
  );
}

export default PlayerInput;
