import styled from 'styled-components';

export const PlayerInputContainer = styled.div`
  color: #ccff00;
  margin-top: 70px;
  padding: 10px 35%;
  @media (max-width: 768px) {
    padding: 10px 20%;
  }
`;

export const ConfirmButton = styled.button`
  background-color: #ccff00;
  color: #070e13;
`;

export const PlayerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const PlayerName = styled.span`
  cursor: pointer;
`;
