import React from "react";
import { Link } from "react-router-dom";
import { FiUsers, FiRotateCw, FiHome, FiGrid, FiHexagon } from "react-icons/fi";

import logo from "../../../../assets/logo.png";

import { HeaderContainer, Profile, HeaderButton, Nav } from "./styles";

const Header: React.FC = () => {

  /*
        <Nav>
        <Link to="/dashboard">
          <HeaderButton>
            <FiHome />
            <span>Início</span>
          </HeaderButton>
        </Link>

        <Link to="/members">
          <HeaderButton>
            <FiUsers />
            <span>Jogadores</span>
          </HeaderButton>
        </Link>

        <Link to="/structure">
          <HeaderButton>
            <FiGrid />
            <span>Times</span>
          </HeaderButton>
        </Link>

        <Link to="/operations">
          <HeaderButton>
            <FiRotateCw />
            <span>Jogos</span>
          </HeaderButton>
        </Link>

        <Link to="/options">
          <HeaderButton>
            <FiHexagon />
            <span>Estatpísticas</span>
          </HeaderButton>
        </Link>
      </Nav>
  */

  return (
    <HeaderContainer>
      <img src={logo} alt="footbras" />
    </HeaderContainer>
  );
};

export default Header;
