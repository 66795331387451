import styled from 'styled-components';

export const TeamSizeContainer = styled.div`
margin-top: 70px;
padding: 10px 40%;
@media (max-width: 768px) {
  padding: 10px 20%;
}
`;

