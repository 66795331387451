import styled from 'styled-components';

export const HistoricContainer = styled.div`
  margin-top: 200px;
  background-color: #070e13;
  color: #ccff00;
  padding: 10px 40px;
  @media (max-width: 768px) {
    padding: 10px 20%;
  }
`;
