import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";
import AppSimple from "../pages/appSimple";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Dashboard from "../pages/Dashboard";
import DashMembers from "../pages/DashMembers";
import DashOperations from "../pages/DashOperations";
import DashStructure from "../pages/DashStructure";
import Profile from "../pages/Profile";
import Options from "../pages/Options";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={AppSimple} />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate/>
      <Route path="/members" component={DashMembers} isPrivate/>
      <Route path="/operations" component={DashOperations} isPrivate/>
      <Route path="/structure" component={DashStructure} isPrivate/>
      <Route path="/profile" component={Profile} isPrivate/>
      <Route path="/options" component={Options} isPrivate/>
    </Switch>
  );
};

export default Routes;
