import React, { useState } from "react";
import "react-day-picker/lib/style.css";

import Panel from "../../components/Panel";
import Modal from "../../components/Modal";
import SideBar from "../../components/Sidebar";
import Button from "../../components/DefaultButton";
import ListPlayers from "../../components/ListPlayers";

import {
  Container,
  Content,
  Schedule,
  Section,
} from "./styles";

const DashboardOperations: React.FC = () => {

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleClosePanel = () => {setIsPanelOpen(false);};
  const openModal = () => {setModalOpen(true);};
  const closeModal = () => {setModalOpen(false);};

  const nomesAleatorios = ["João", "Diego", "Pedro", "Lucas", "Carlos", "Ronaldo", "Gustavo", "Jorge"];
  const sobrenomesAleatorios = ["Martinez", "Santos", "Souza", "Ferreira", "Alves", "Oliveira", "Pereira", "Costa", "Rodrigues"];
  const papeisAleatorios = [
    "Atacante",
    "MeioCampo",
    "Zagueiro",
    "Goleiro",
  ];

  const avataresAleatorio = [
    "https://storage.googleapis.com/aligna-files/profile/c1202b1da2ed493f9c952190513fdaca.png",
    "https://storage.googleapis.com/aligna-files/profile/b4e34a9721e33e443a6121c266993093.png",
    "https://storage.googleapis.com/aligna-files/profile/0236d342f3dd07d7be8bc81139417020.png",
    "https://storage.googleapis.com/aligna-files/profile/a27c42b041ee68d57e3b36f4a699c24b.png",
    "https://storage.googleapis.com/aligna-files/profile/3804646a2f806a429d358d1b9c292a4b.png",
    "https://storage.googleapis.com/aligna-files/profile/e1226851cb0d5f551dbd8a1c8e5df351.png",
    "https://storage.googleapis.com/aligna-files/profile/cdea09313fd85575c024a269e24ab2a3.png",
    "https://storage.googleapis.com/aligna-files/profile/c06d4a9c8f09909bf891a60e272525c4.png"
,
  ];
  const membros = Array.from({ length: 30 }, (_, index) => {
  const randomNome = nomesAleatorios[Math.floor(Math.random() * nomesAleatorios.length)];
  const randomSobrenome = sobrenomesAleatorios[Math.floor(Math.random() * sobrenomesAleatorios.length)];
  const randomSetores = papeisAleatorios[Math.floor(Math.random() * papeisAleatorios.length)];
  const randomAvatar = avataresAleatorio[Math.floor(Math.random() * avataresAleatorio.length)];
  return {
      id: index,
      avatar: `${randomAvatar}`,
      nome: `${randomNome} ${randomSobrenome}`,
      setor: `${randomSetores}`,
    };
  });

  return (
    <Container>
      <SideBar />

      <Content>
        <Schedule>
          <h1>Membros</h1>
          
          <Section>
            <div className="App">
            
            <Panel isOpen={isPanelOpen} onClose={handleClosePanel}>
            <h2>Painel</h2>
              <p>Este é o painel que você pode utilizar para visualizar um POP.</p>
              <p>Nome do POP</p>
              <p>Descrição do POP.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Panel>
          </div>

          <div>
            <Button onClick={openModal}>Criar Membro</Button>
            <Modal tituloDaModal="Criar POP" isOpen={modalOpen} onClose={closeModal}>
              <p>Este é o modal que você pode utilizar para Criar e editar um POP.</p>
              <p>Nome do POP</p>
              <p>Descrição do POP.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Modal>
          </div>
          </Section>
          <Section>
            <div className="list">
              {membros.map((membro) => (
                <ListPlayers 
                  key={membro.id}
                  avatar={membro.avatar}
                  nome={membro.nome}
                  setor={membro.setor}
                />
              ))}
            </div>

            {/* ... (Buttons and Panels) */}
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default DashboardOperations;
