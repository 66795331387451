import React from "react";
import { RoutineContainer, RoutineInfo, StatusIndicator } from "./styles";

interface ListRoutinesProps {
  titulo: string;
  setor: string;
  papel: string;
  status: string;
  opcao: string;
}

const ListRoutines: React.FC<ListRoutinesProps> = ({ titulo, setor, papel, status, opcao }) => {
  return (
    <RoutineContainer>
      <StatusIndicator status={status} />
      <RoutineInfo>
        <div>
          <strong>{titulo}</strong>
          <span>{setor}</span>
          <span>{papel}</span>
        </div>
        <div>
          <span>{opcao}</span>
        </div>
      </RoutineInfo>
    </RoutineContainer>
  );
};

export default ListRoutines;