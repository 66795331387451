import React, { FC } from 'react';
import DefaultButton from "../../../../components/DefaultButton"
import {TeamSizeContainer} from './styles';

interface TeamSizeProps {
  onNext: (size: number) => void;
}

const TeamSize: FC<TeamSizeProps> = ({ onNext }) => {
  const playersFromStorage = localStorage.getItem('footbras:players');
  const players = playersFromStorage ? JSON.parse(playersFromStorage) : [];

  const generateTeams = (teamSize: number) => {
    let shuffledPlayers = [...players];
    for (let i = shuffledPlayers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledPlayers[i], shuffledPlayers[j]] = [shuffledPlayers[j], shuffledPlayers[i]];
    }

    const teams = [];
    while (shuffledPlayers.length) {
      teams.push(shuffledPlayers.splice(0, teamSize));
    }

    localStorage.setItem('footbras:teams', JSON.stringify(teams));
  }

  const handleNext = (size: number) => {
    generateTeams(size);
    onNext(size);
  }

  return (
    <TeamSizeContainer>
      <h2>Escolher Tamanho de Equipes</h2>
      <DefaultButton onClick={() => handleNext(3)}>3</DefaultButton>
      <DefaultButton onClick={() => handleNext(4)}>4</DefaultButton>
      <DefaultButton onClick={() => handleNext(5)}>5</DefaultButton>
      <DefaultButton onClick={() => handleNext(6)}>6</DefaultButton>
      <DefaultButton onClick={() => handleNext(7)}>7</DefaultButton>
      <DefaultButton onClick={() => handleNext(8)}>8</DefaultButton>
      <DefaultButton onClick={() => handleNext(9)}>9</DefaultButton>
      <DefaultButton onClick={() => handleNext(10)}>10</DefaultButton>
      <DefaultButton onClick={() => handleNext(11)}>11</DefaultButton>
    </TeamSizeContainer>
  );
}

export default TeamSize;
