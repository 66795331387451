import styled from 'styled-components';

export const AppContainer = styled.div`
  color: #ccff00;  // Verde
  padding: 20px;
  text-align: center;

  h1{
    margin-bottom: 30px;
  }
  img{
    @media (max-width: 768px) {

    }
  }
`;

export const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;
