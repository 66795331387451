import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import Panel from "../../components/Panel";
import Modal from "../../components/Modal";
import SideBar from "../../components/Sidebar";
import Button from "../../components/DefaultButton";
import ListTeams from "../../components/ListTeams";

import {
  Container,
  Content,
  Schedule,
  Section,
} from "./styles";

const DashboardSector: React.FC = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenPanel = () => setIsPanelOpen(true);
  const handleClosePanel = () => setIsPanelOpen(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const titulosAleatorios = ["Santos", "São Paulo", "Palmeiras", "Botafogo", "Cruzeiro", "Flamengo", "Grêmio"];

  const setoresAleatorios = ["São Paulo", "Paraíba", "Minas Gerais", "Rio de Janeiro"];
  
  const papeisAleatorios = [
    "Atacante",
    "Meio Campo",
    "Goleiro",
    "Zagueiro",
  ];

  const setors = Array.from({ length: 30 }, (_, index) => {
    const randomTitulo = titulosAleatorios[Math.floor(Math.random() * titulosAleatorios.length)];
    const randomSetor = setoresAleatorios[Math.floor(Math.random() * setoresAleatorios.length)];
    const randomPapel = papeisAleatorios[Math.floor(Math.random() * papeisAleatorios.length)];
    const randomPapel2 = papeisAleatorios[Math.floor(Math.random() * papeisAleatorios.length)];
    const randomPapel3 = papeisAleatorios[Math.floor(Math.random() * papeisAleatorios.length)];

    return {
      id: index + 4,
      titulo: randomTitulo,
      setor: randomSetor,
      papel: randomPapel,
      papel2: randomPapel2,
      papel3: randomPapel3
    };
  });

  return (
    <Container>
      <SideBar />
      <Content>
        <Schedule>
          <h1>Setores</h1>
          <Section>
            <div className="App">
            <Button onClick={handleOpenPanel}>Visualizar Setor</Button>
            <Panel isOpen={isPanelOpen} onClose={handleClosePanel}>
            <h2>Painel</h2>
              <p>Este é o painel que você pode utilizar para visualizar um Setor.</p>
              <p>Nome do Setor</p>
              <p>Descrição do Setor.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Panel>
          </div>

          <div>
            <Button onClick={openModal}>Criar Setor</Button>
            <Modal tituloDaModal="Criar POP" isOpen={modalOpen} onClose={closeModal}>
              <p>Este é o modal que você pode utilizar para Criar e editar um Setor.</p>
              <p>Nome do Setor</p>
              <p>Descrição do Setor.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Modal>
          </div>
          </Section>
          <Section>
          <div className="list">
            {setors.map((setor) => (
              <ListTeams
                key={setor.id}
                titulo={setor.titulo}
                setor={setor.setor}
                papel={setor.papel}
                papel2={setor.papel2}
                papel3={setor.papel3}
              />
            ))}
          </div>
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default DashboardSector;
