import React from "react";
import { MemberContainer, Avatar, MemberInfo } from "./styles"; // Importe as novas estilizações

interface ListMembersProps {
  avatar: string;
  nome: string;
  setor: string;
}

const ListMembers: React.FC<ListMembersProps> = ({ avatar, nome, setor }) => {
  return (
    <MemberContainer>
      <Avatar>
        <img src={avatar} alt={`Avatar de ${nome}`} />
      </Avatar>
      <MemberInfo>
        <strong>{nome}</strong>
        <span>{setor}</span>
      </MemberInfo>
    </MemberContainer>
  );
};

export default ListMembers;