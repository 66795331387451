import React, { useState } from "react";
import "react-day-picker/lib/style.css";

import Panel from "../../components/Panel";
import Modal from "../../components/Modal";
import SideBar from "../../components/Sidebar";
import Button from "../../components/DefaultButton";

import {
  Container,
  Content,
  Schedule,
  Section,
} from "./styles";

const Options: React.FC = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenPanel = () => { setIsPanelOpen(true);};
  const handleClosePanel = () => {setIsPanelOpen(false);};
  const openModal = () => {setModalOpen(true);};
  const closeModal = () => {setModalOpen(false);};

  return (
    <Container>
      <SideBar />

      <Content>
        <Schedule>
          <h1>Organização</h1>
          
          <Section>
            <div className="App">
            <Button onClick={handleOpenPanel}>Visualizar mais opções da organização</Button>
            <Panel isOpen={isPanelOpen} onClose={handleClosePanel}>
            <h2>Painel</h2>
              <p>Este é o painel que você pode utilizar para visualizar um POP.</p>
              <p>Nome do POP</p>
              <p>Descrição do POP.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Panel>
          </div>

          <div>
            <Button onClick={openModal}>Editar organização</Button>
            <Modal tituloDaModal="Criar POP" isOpen={modalOpen} onClose={closeModal}>
              <p>Este é o modal que você pode utilizar para Criar e editar um POP.</p>
              <p>Nome do POP</p>
              <p>Descrição do POP.</p>
              <p>Setor.</p>
              <p>Papel.</p>
            </Modal>
          </div>
          </Section>
          <Section>
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default Options;
