import React from "react";
import SideBar from "../../components/Sidebar";

import {
  Container,
  Content,
  Schedule,
  Section,
} from "./styles";


const Dashboard: React.FC = () => { 
  return (
    <Container>
      <SideBar />
      <Content>
        <Schedule>
          <h1>Dashboard</h1>
                   <Section>
            <strong>Manhã</strong>
          </Section>
          <Section>
            <strong>Tarde</strong>
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default Dashboard;
