import React, { FC, useEffect } from 'react';
import DefaultButton from "../../../../components/DefaultButton"
import { TeamNamesContainer } from './styles';
interface TeamNamesProps {
  onNext: (nameType: string) => void;
}

const animalNames = ["Tigers", "Lions", "Sharks", "Eagles", "Rabbits", "Monster", "Bulls", "Horses"];
const cityNames = ["Santos", "São Vicente", "São Paulo", "Praia Grande", "Guarujá", "Campos", "São Francisco", "Madrid"];
const internationalTeams = ["Barcelona", "Real Madrid", "Inter de Milão", "Chelsea", "Liverpool", "Newcastle", "PSG", "Bayern"];
const countriesNames = ["Brasil", "Espanha", "Itália", "Holanda", "Alemanha", "França", "Bélgica", "Inglaterra"];
const colorNames = ["Azul", "Vermelho", "Amarelo", "Verde", "Laranja", "Branco", "Cinza", "Preto"];

const TeamNames: FC<TeamNamesProps> = ({ onNext }) => {
  const setTeamNames = (nameType: string) => {
    let nameList: string[] = [];
    switch (nameType) {
      case 'Animais':
        nameList = animalNames;
        break;
      case 'Cidades':
        nameList = cityNames;
        break;
      case 'Times':
        nameList = internationalTeams;
        break;
      case 'Países':
        nameList = countriesNames;
        break;
      case 'Cores':
        nameList = colorNames;
        break;
      default:
        nameList = [];
    }

    const existingTeams = JSON.parse(localStorage.getItem('footbras:teams') || '[]');
    const updatedTeams = existingTeams.map((team: any, index: number) => {
      const teamNumber = String(index).padStart(2, '0');
      return {
        name: `${nameList[index % nameList.length]}`,
        number: `${teamNumber}`,
        players: team,
      };
    });

    localStorage.setItem('footbras:teams', JSON.stringify({ name: nameType, teams: updatedTeams }));
  }

  const handleNext = (nameType: string) => {
    setTeamNames(nameType);
    onNext(nameType);
  }

  return (
    <TeamNamesContainer>
      <h2>Escolher Nomes de Equipes</h2>
      <DefaultButton onClick={() => handleNext('Animais')}>Animais</DefaultButton>
      <DefaultButton onClick={() => handleNext('Cidades')}>Cidades</DefaultButton>
      <DefaultButton onClick={() => handleNext('Times')}>Times</DefaultButton>
      <DefaultButton onClick={() => handleNext('Países')}>Países</DefaultButton>
      <DefaultButton onClick={() => handleNext('Cores')}>Cores</DefaultButton>
    </TeamNamesContainer>
  );
}

export default TeamNames;
