import styled from "styled-components";
import { shade } from "polished";
import { style } from "../../components/AppGlobalStyle";

export const Container = styled.div`
  color: ${style.secondary};
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  color: ${style.secondary};
  display: flex;
  padding: 20px;

  @media (min-width: 768px) {
    margin-left: auto;
  }
`;

export const Schedule = styled.div`
  margin-right: 120px;

  flex: 1;

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 32px;
    color: ${style.primary};
    font-weight: 500;

    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    span + span::before {
      content: "";
      width: 1px;
      height: 12px;
      background: ${style.primary};
      margin: 0 8px;
    }
  }
`;

export const NextAppointment = styled.div`
  margin-top: 64px;

  > strong {
    color: ${style.secondary};
    font-size: 20px;
    font-weight: 400;
  }

  div {
    background: #3e3b46;
    padding: 16px 24px;
    border-radius: ${style.borderRadius};
    margin-top: 24px;
    position: relative;

    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      left: 0;
      top: 10%;
      background: ${style.primary};
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color:  ${style.primarycontrastcolor};
    }

    span {
      margin-left: auto;
      color: ${style.primarycontrastcolor};

      display: flex;
      align-items: center;

      svg {
        color: ${style.primary};
        margin-right: 8px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: ${style.secondary};
    font-size: 20px;
    line-height: 26px;
    display: block;
    border-bottom: 1px solid ${style.secondary};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: ${style.secondary};
    font-weight: 400;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  span {
    margin-left: auto;
    color: ${style.primarycontrastcolor};
    width: 72px;

    display: flex;
    align-items: center;

    svg {
      color: ${style.primary};
      margin-right: 8px;
    }
  }

  div {
    padding: 16px 24px;
    border-radius: ${style.borderRadius};
    margin-left: 24px;

    display: flex;
    align-items: center;
    flex: 1;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color: ${style.backgroundcontrastcolor};
      font-size: 20px;
    }
  }
`;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    background: color: ${style.background};
    border-radius: ${style.borderRadius};
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #111721;
    border-radius: ${style.borderRadius};
    color: ${style.secondarycontrastcolor};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, style.primary)};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: ${style.secondary} !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: ${style.primary} !important;
    border-radius: ${style.borderRadius};
    color: ${style.secondary} !important;
  }
`;
