import styled from "styled-components";
import { style } from "../AppGlobalStyle";

export const SidebarContainer = styled.div`
  width: 250px;
  height: 100%;
  padding: 32px 0;
  background: ${style.secondary};
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    width: 150px;
    height: 44px;
  }

  @media (max-width: 768px) {
    width: 80px;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  align-items: center;

  img {
    display: block;
    width: 39.2px;
    height: 39.2px;
    border-radius: 50%;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div {
    line-height: 24px;
    display: flex;
    flex-direction: column;

    span {
      color: ${style.secondarycontrastcolor};
    }

    a {
      color: ${style.primary};

      &:hover {
        opacity: 0.8;
        color: ${style.primary};
      }
    }
  }
`;

export const SidebarButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.2s;
  cursor: pointer;
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text horizontally */
  color: ${style.secondarycontrastcolor};

  svg {
    height: 20px;
    width: 20px;
  }

  span {
    margin-top: 10px; /* Distance between icon and text */
  }

  &:hover {
    color: ${style.primary};
    svg {
      color: ${style.primary};
    }
    span {
      color: ${style.primary};
    }
  }
  @media (max-width: 768px) {
    span {
      display: none;
      transition: 0.5s;
    }
  }
`;