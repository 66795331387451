import styled from "styled-components";
import { style } from "../AppGlobalStyle";

export const RoutineContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: ${style.backgroundClearComponent};
  border-radius: ${style.borderRadius};
  margin-bottom: 15px;
  box-shadow: ${style.boxshadow};
`;

export const StatusIndicator = styled.div<{ status: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${(props) =>
    props.status === "Conforme"
      ? style.successcontrast
      : props.status === "Não Conforme"
      ? style.errorcontrast
      : style.alertcontrast};
`;

export const RoutineInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
  }

  strong {
    font-size: 18px;
    color: ${style.backgroundClearComponentContrastText};
  }

  span {
    margin-top: 4px;
    font-size: 14px;
    color: ${style.backgroundClearComponentContrastText};
  }
`;