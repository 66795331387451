import React, { FC, useState, useEffect  } from 'react';
import DefaultButton from "../../../../components/DefaultButton"
import { MatchTypeContainer, TeamCard, TeamTitle, TeamPlayers } from './styles';

interface MatchTypeProps {
  onNext: (matchType: string) => void;
}

const MatchType: FC<MatchTypeProps> = ({ onNext }) => {
  const [teamsData, setTeamsData] = useState<any[]>([]);

  useEffect(() => {
    const existingTeams = JSON.parse(localStorage.getItem('footbras:teams') || '[]');
    if (existingTeams.teams) {
      setTeamsData(existingTeams.teams);
    }
  }, []);

  const handleNext = (matchType: string) => {
    if (matchType === 'Sistema de Próximo') {
      createQueue();
    }
    onNext(matchType);
  };

    const createQueue = () => {
    const existingTeams = JSON.parse(localStorage.getItem('footbras:teams') || '[]');

    const teamsWithIds = existingTeams.map((team: any, index: number) => ({
      id: (index + 1).toString().padStart(2, '0'),
      ...team
    }));

    localStorage.setItem('footbras:teams', JSON.stringify(teamsWithIds));
    const queue = teamsWithIds.map((team: any) => team.id);
/*
    <h2>Tipo de Disputa</h2>
    <DefaultButton onClick={() => handleNext('Próximo')}>Próximo</DefaultButton>
    <DefaultButton onClick={() => handleNext('Mata Mata')}>Mata Mata</DefaultButton>
    <DefaultButton onClick={() => handleNext('Pontos Corridos')}>Pontos Corridos</DefaultButton>
*/
  };

  return (
    <MatchTypeContainer>
      <h3>Times existentes:</h3>
      <div>
        {teamsData.map((team, index) => (
          <TeamCard key={index}>
            <TeamTitle>ID: {team.number}</TeamTitle>
            <TeamTitle>Nome do time: {team.name}</TeamTitle>
            <TeamPlayers>Jogadores: {team.players.join('  |  ')}</TeamPlayers>
          </TeamCard>
        ))}
      </div>
    </MatchTypeContainer>
  );
};

export default MatchType;
