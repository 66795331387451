import styled, { css } from "styled-components";
import { animated } from "react-spring";
import { style } from "../../AppGlobalStyle";

interface ContainerProps {
  type?: "success" | "error" | "info";
  hasDescription: number;
}

const toastTypeVariations = {
  info: css`
    background: ${style.infobackground};
    color: ${style.infocontrast};
  `,
  success: css`
  background: ${style.successbackground};
  color: ${style.successcontrast};
  `,
  error: css`
    background: ${style.errorbackground};
    color: ${style.errorcontrast};
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  position: relative;
  width: 360px;
  padding: 16px 30px 16px 16px;
  border-radius: ${style.borderRadius};
  box-shadow: ${style.boxshadow};
  display: flex;

  & + div {
    margin-top: 16px;
  }

  ${props => toastTypeVariations[props.type || "info"]}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    background: transparent;
    border: 0;
    color: inherit;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
