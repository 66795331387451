import styled from "styled-components";
import { style } from "../../components/AppGlobalStyle";

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 70%;
  height: 100%;
  background-color: ${style.backgroundClearComponent};
  box-shadow: ${style.boxshadow};
  transition: right 0.5s ease;
  z-index: 999;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 20px;

  h2 {
    margin-bottom: 10px;
    color: ${style.backgroundClearComponentContrastText};
  }
`;