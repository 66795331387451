import React from "react";

import { Overlay, Container, Content } from "./styles";
import Button from "../DefaultButton";

interface ModalProps {
  tituloDaModal: string;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ tituloDaModal, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <Container>
        <Content>
          <h2>{tituloDaModal}</h2>
          {children}
          <Button onClick={onClose}>Fechar</Button>
        </Content>
      </Container>
    </Overlay>
  );
};

export default Modal;