export const style = {
    //PALETA Cores e Background
    primary: "#ccff00",
    secondary: "#11191C",
    background: "#f3f7f9",
    backgroundClearComponent: "#ffffff",
    text: "#333",

    //PALETA Textoe contraste /*Letras em contraste
    primarycontrastcolor: "#11191C",
    secondarycontrastcolor: "#ccff00",
    backgroundcontrastcolor: "#11191C",
    backgroundModal: "#f3f7f9",
    backgroundClearComponentContrastText: "#222831",

    //NOTIFICAÇÕES
    successbackground: "#e6fffa",
    errorbackground: "#fddede",
    alertbackground: "#fffbea",
    infobackground: "#ebf8ff",
    successcontrast: "#2e656a",
    errorcontrast: "#c53030",
    alertcontrast: "#8a6200",
    infocontrast: "#3172b7",

    //Bordas de botões e modais.
    borderRadius: "3px",
    boxshadow: "0px 1px 2px #00000033",
  };
