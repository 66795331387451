import React, { FC, useState, useEffect } from 'react';
import {GameControlContainer} from './styles';
import DefaultButton from "../../../../components/DefaultButton"

const GameControl: FC = () => {
  // Estados
  const [teams, setTeams] = useState<any[]>([]);
  const [currentMatch, setCurrentMatch] = useState<{team1: any, team2: any} | null>(null);
  const [timer, setTimer] = useState<number>(0);
  const [goals, setGoals] = useState<any[]>([]);

  // Iniciar cronômetro quando o jogo começa
  useEffect(() => {
    let timerID: NodeJS.Timeout;
    if (currentMatch) {
      timerID = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => {
      clearInterval(timerID);
    };
  }, [currentMatch]);

  // Simular carregamento dos times (você deve carregar do localStorage em um caso real)
  useEffect(() => {
    const loadedTeams = JSON.parse(localStorage.getItem('footbras:teams') || '[]');
    setTeams(loadedTeams);
    if (loadedTeams.length >= 2) {
      setCurrentMatch({ team1: loadedTeams[0], team2: loadedTeams[1] });
    }
  }, []);

  // Função para adicionar gol
  const addGoal = (teamID: string, player: string) => {
    setGoals([...goals, { teamID, player }]);
  };

  // Função para mudar para o próximo jogo (exemplo simples)
  const nextGame = () => {
    const winner = goals.length ? goals[goals.length - 1].teamID : null; // Pegar o último time que marcou como vencedor
    let nextTeams = [...teams];
    if (winner) {
      nextTeams.push(nextTeams.shift()!); // Mover o time vencedor para o final da fila
    }
    setCurrentMatch({ team1: nextTeams[0], team2: nextTeams[1] });
    setGoals([]); // Limpar os gols
    setTimer(0); // Resetar o timer
  };

  return (
    <GameControlContainer>
      <h2>Controle de Partidas</h2>
      <div>
        <h3>Tempo: {timer}s</h3>
        <h4>Jogo Atual: {currentMatch ? `${currentMatch.team1.name} vs ${currentMatch.team2.name}` : 'Nenhum'}</h4>
        <DefaultButton onClick={() => addGoal(currentMatch?.team1.id, 'Jogador1')}>Adicionar Gol Time 1</DefaultButton>
        <DefaultButton onClick={() => addGoal(currentMatch?.team2.id, 'Jogador2')}>Adicionar Gol Time 2</DefaultButton>
        <DefaultButton onClick={nextGame}>Próximo Jogo</DefaultButton>
      </div>
    </GameControlContainer>
  );
}

export default GameControl;
