import React, { FC } from 'react';
import { HistoricContainer } from './styles';

interface HistoricProps {
  // Adicione qualquer propriedade que você precise aqui
}

const Historic: FC<HistoricProps> = () => {
  return (
    <HistoricContainer>
      <h2>Histórico</h2>
      {/* Aqui vão os detalhes do que aconteceu durante o campeonato */}
    </HistoricContainer>
  );
}

export default Historic;
