import styled from "styled-components";
import { shade } from "polished";
import { style } from "../AppGlobalStyle";

export const Container = styled.button`
  background: ${style.primary};
  color: ${style.primarycontrastcolor};
  font-size: 16px;
  margin: 16px 10px;
  border-radius: ${style.borderRadius};
  border: none;
  outline-style: none;
  width: 150px;
  padding: 8px 0;
  transition: 0.2s;

  span{
    color: ${style.secondarycontrastcolor};
  }

  &:hover {
    background: ${shade(0.2, style.primary)};
  }
`;
