import styled from 'styled-components';

export const MatchTypeContainer = styled.div`
margin-top: 70px;
padding: 10px 30%;
@media (max-width: 768px) {
  padding: 10px 20%;
}
`;

export const TeamCard = styled.div`
  background-color: #202E37;
  border: 1px solid #474e63;
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

export const TeamTitle = styled.h4`
  margin: 0;
  padding: 0;
`;

export const TeamPlayers = styled.p`
  font-size: 14px;
  color: #fff;
`;
