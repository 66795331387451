import styled from "styled-components";
import { style } from "../../components/AppGlobalStyle";

export const Container = styled.div``;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  color: ${style.secondary};;
  display: flex;
`;

export const Schedule = styled.div`
  margin-right: 120px;

  flex: 1;

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 32px;
    color: ${style.primary};
    font-weight: 500;

    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    span + span::before {
      content: "";
      width: 1px;
      height: 12px;
      background: ${style.primary};
      margin: 0 8px;
    }
  }
`;

export const NextAppointment = styled.div`
  margin-top: 64px;

  > strong {
    color: ${style.secondary};
    font-size: 20px;
    font-weight: 400;
  }

  div {
    background: #3e3b46;
    padding: 16px 24px;
    border-radius: ${style.borderRadius};
    margin-top: 24px;
    position: relative;

    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      height: 80%;
      width: 1px;
      left: 0;
      top: 10%;
      background: ${style.primary};
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color: ${style.primarycontrastcolor};
    }

    span {
      margin-left: auto;
      color: ${style.secondarycontrastcolor};

      display: flex;
      align-items: center;

      svg {
        color: ${style.primary};
        margin-right: 8px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: ${style.secondarycontrastcolor};
    font-size: 20px;
    line-height: 26px;
    display: block;
    border-bottom: 1px solid #222731;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: ${style.secondarycontrastcolor};
    font-weight: 400;
  }
`;