import React from "react";
import { SectorContainer, SectorInfo, SectorBadge } from "./styles";

interface ListSectorsProps {
  titulo: string;
  setor: string;
  papel: string;
  papel2: string;
  papel3: string;
}

const ListSectors: React.FC<ListSectorsProps> = ({ titulo, setor, papel, papel2, papel3 }) => {
  const setorInitials = setor.substring(0, 3).toUpperCase();

  return (
    <SectorContainer>
      <SectorBadge>{setorInitials}</SectorBadge>
      <SectorInfo>
        <div>
          <strong>{titulo}</strong>
          <span>{setor}</span>
        </div>
        <div>
          <span>{papel}</span>
          <span>{papel2}</span>
          <span>{papel3}</span>
        </div>
      </SectorInfo>
    </SectorContainer>
  );
};

export default ListSectors;
