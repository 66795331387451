import React, { FC, useState } from 'react';
import Header from './components/Header';
import PlayerInput from './components/PlayerInput';
import TeamSize from './components/TeamSize';
import TeamNames from './components/TeamNames';
import MatchType from './components/MatchType';
import GameControl from './components/GameControl';
import Stats from './components/Stats';
import Historic from './components/Historic';
import {AppContainer} from './styles';
import futebol from "../../assets/full3.jpg";

const ChampionshipOrganizer: FC = () => {
  const [step, setStep] = useState<number>(1);

  const onNext = () => {
    setStep(step + 1);
  };

  return (

    <AppContainer>
    <Header/>
      {step === 1 && <PlayerInput onNext={onNext} />}
      {step === 2 && <TeamSize onNext={onNext} />}
      {step === 3 && <TeamNames onNext={onNext} />}
      {step === 4 && <MatchType onNext={onNext} />}
      {step === 5 && <GameControl />}
      {step === 6 && <Stats />}
      {step === 7 && <Historic />}

      <div>
        <a href="https://click.linksynergy.com/fs-bin/click?id=iIRuSWeaH7A&offerid=951620.6&type=3&subid=">
          <img src={futebol}/>
        </a>
      </div>
    </AppContainer>
  );
}

export default ChampionshipOrganizer;
