import { SignInFormData } from "../pages/SignIn";

const mockUser = {
  id: "1",
  name: "Diego",
  email: "diego@footbras.com.br",
  avatar_url: "https://example.com/avatar.png",
};

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function signInMock(data: SignInFormData) {
  await delay(100);

  if (data.email === "diego@footbras.com.br" && data.password === "footbras") {
    return mockUser;
  } else {
    throw new Error("Invalid credentials");
  }
}