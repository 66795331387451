import { createGlobalStyle } from "styled-components";
import { style } from "../components/AppGlobalStyle";
import marca from "../assets/logopoweredgrand.png"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background-image: url(${marca});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-color: #202E37;  // Azul
    color: ${style.backgroundcontrastcolor};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: "Montserrat";
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
`;
