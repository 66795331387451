import React from "react";
import { Link } from "react-router-dom";
import { FiPower, FiUsers, FiRotateCw, FiHome, FiGrid, FiHexagon } from "react-icons/fi";


import { useAuth } from "../../hooks/auth";

import logo from "../../assets/logo.png";
import icon from "../../assets/icon.png";
import poweredbyfootbras from "../../assets/logopowered.png";

import { SidebarContainer, Profile, SidebarButton } from "./styles";

const Sidebar: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <SidebarContainer>
      <img src={window.innerWidth <= 768 ? icon : logo} alt="poweredbyfootbras" />

      <Profile>
        <img src="https://storage.googleapis.com/aligna-files/profile/fc828e29a06aa7daa92fb263de5bfc80.png" alt={user.name} />

        <div>
        <Link to="/profile"><span>Bem-vindo, </span><strong>{user.name}</strong>
          </Link>
        </div>
      </Profile>

      <Link to="/dashboard">
      <SidebarButton>
        <div>
          <FiHome />
        </div>
        <span>Dashboard</span>
      </SidebarButton>
      </Link>

      <Link to="/members">
      <SidebarButton>
        <div>
          <FiUsers />
        </div>
        <span>Jogadores</span>
      </SidebarButton>
      </Link>

      <Link to="/structure">
      <SidebarButton>
        <div>
          <FiGrid />
        </div>
        <span>Times</span>
      </SidebarButton>
      </Link>

      <Link to="/operations">
      <SidebarButton>
        <div>
          <FiRotateCw />
        </div>
        <span>Eventos</span>
      </SidebarButton>
      </Link>

      <Link to="/options">
      <SidebarButton>
        <div>
          <FiHexagon />
        </div>
        <span>Configurações</span>
      </SidebarButton>
      </Link>

      <SidebarButton onClick={signOut}>
        <div>
          <FiPower />
        </div>
        <span>Sair</span>
      </SidebarButton>

      <img src={window.innerWidth <= 768 ? icon : poweredbyfootbras} alt="footbras" />
    </SidebarContainer>
  );
};

export default Sidebar;
